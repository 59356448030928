@tailwind base;
@tailwind components;
@tailwind utilities;

@import "abstracts/mixins";
@import "abstracts/variables";

@import "base/animation";
@import "base/base";
@import "base/fonts";
@import "base/typography";
@import "base/utilities";

@import "components/cursor";