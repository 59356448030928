/* Acumin Pro Wide Regular */
@font-face {
    font-family: 'acumin-pro-wide';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('../fonts/acuminprowide-regular-webfont.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/acuminprowide-regular-webfont.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  
/* Acumin Pro Wide Semibold */
@font-face {
    font-family: 'acumin-pro-wide';
    font-style: normal;
    font-weight: 600;
    src: local(''),
         url('../fonts/acuminprowide-semibold-webfont.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/acuminprowide-semibold-webfont.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  
    