.clearfix,
%clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin clearfix {
  @extend %clearfix;
}

@mixin wrapper-max($width: $wrapper-max) {
  max-width: $width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

// $breakpoints to generate grid classes
$breakpoints: (
  xs: (
    min: 0,
    max: (
      34.375em - 0.0625em
    ),
    // 549px
  ),
  sm: (
    min: 34.375em,
    // 550px
      max:
      (
        48em - 0.0625em
      ),
    // 767px
  ),
  md: (
    min: 48em,
    // 768px
      max:
      (
        58.75em - 0.0625em
      ),
    // 939px
  ),
  lg: (
    min: 58.75em,
    // 940px
      max:
      (
        68.75em - 0.0625em
      ),
    // 1099px
  ),
  xl: (
    min: 68.75em,
    // 1100px
      max: "none"
  )
) !default;

@mixin media-query($mq, $mq-max: false) {
  $breakpoint-found: false;

  // Loop through the list of breakpoints we’ve provided in our settings file.
  @each $breakpoint, $screen in $breakpoints {
    // Grab the alias and the condition from their respective locations in
    // the list.

    // min-width
    $size: $breakpoint; // (xs)

    $alias-min: "screen-#{$size}"; // (screen-xs)
    $alias-max: "screen-#{$size}-max"; // (screen-xs-max)
    $condition-min: map-get($screen, min);
    $condition-max: map-get($screen, max);

    //@warn "mq: #{$mq}";

    //@warn "size: #{$size}, alias: #{$alias-min} - #{$alias-max}, condition: #{$condition-min} #{$condition-max}";

    // If the media query we’ve specified has an alias and a condition...
    @if $mq == $alias-min and $mq-max == false {
      // ...tell the mixin that we’ve found it...
      $breakpoint-found: true;

      // ...and spit it out here.
      @media screen and (min-width: #{$condition-min}) {
        @content;
      }
    }
    @if $mq == $alias-max {
      // ...tell the mixin that we’ve found it...
      $breakpoint-found: true;

      // ...and spit it out here.
      @media screen and (max-width: #{$condition-max}) {
        @content;
      }
    }
    @if ($mq == $alias-min and $mq-max == $alias-max) {
      // ...tell the mixin that we’ve found it...
      $breakpoint-found: true;

      // ...and spit it out here.
      @media screen and (min-width: #{$condition-min}) and (max-width: #{$condition-max}) {
        @content;
      }
    }
  }

  // If the user specifies a non-exitent alias, send them a warning.
  @if $breakpoint-found == false {
    @warn "Arrr! Breakpoint ‘#{$mq}’ existiert nicht.";
  }
}

// basic styles for lists used as navs
@mixin navigation() {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
}

// vertical align
@mixin vertical-align($position: 50%, $correct: -50%) {
  position: relative;
  top: $position;
  -webkit-transform: translateY($correct);
  -ms-transform: translateY($correct);
  transform: translateY($correct);
}

@mixin padding-default($column__gutter--half: $grid__gutter--half) {
  padding-left: $column__gutter--half;
  padding-right: $column__gutter--half;
}

// make-row
@mixin make-row($column__gutter--half: $grid__gutter--half) {
  @extend %clearfix;

  margin-left: $column__gutter--half * -1;
  margin-right: $column__gutter--half * -1;
}

// column-floating
@mixin column-floating($column__gutter--half: $grid__gutter--half) {
  float: left;
  padding-left: $column__gutter--half;
  padding-right: $column__gutter--half;
}

// make-column mixin
@mixin make-col($columns) {
  @include column-floating($grid__gutter--half);

  width: percentage($columns/$grid__columns);
}

// make-width mixin
// [1] fallback for browser that can't calculate
@mixin make-width($width, $align: auto) {
  $width-percentage: $width / $grid__columns * $wrapper-max;

  max-width: $width-percentage; //[1]
  max-width: calc(#{$width-percentage} - #{$grid__gutter});

  @if $align == auto or center {
    margin-left: auto;
    margin-right: auto;
  }

  @if $align == left {
    margin-left: 0;
    margin-right: auto;
  }

  @if $align == right {
    margin-right: 0;
    margin-left: auto;
  }
}

// make-offset
@mixin make-offset($width, $direction: left) {
  @if $direction == left or none {
    margin-left: percentage($width / $grid__columns);
  }
}

// screen reader only
@mixin invisible {
  width: 0;
  height: 0;
  left: -1000px;
  top: -1000px;
  position: absolute;
  overflow: hidden;
  display: inline;
}
