.custom-cursor {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  top: -30px;
  left: -30px;
  width: 60px;
  height: 60px;
  z-index: 1000;
  will-change: transform;
}

.custom-cursor-pointer {
  width: 20px;
  height: 20px;

  transform: translate(100%, 100%);
  transition: transform 0.5s cubic-bezier(.65,0,0,.99), opacity 0.5s cubic-bezier(.65,0,0,.99);

  background-color: #393B5D;
  border-radius: 50%;
}

.custom-cursor--inverted .custom-cursor-pointer {
  background-color: #EFF0EA;
}


.custom-cursor--link {
  background-image: url('../images/hand.svg');
  mix-blend-mode: normal;  
}
.custom-cursor--link.custom-cursor--coffee {
  background-color: transparent;
  background-image: url('../images/coffee.svg');
}

.custom-cursor-inner {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  background-image: url('../images/click.svg');
  opacity: 0;
}

.custom-cursor--link .custom-cursor-inner {
  transition: opacity 0.5s cubic-bezier(.17,.67,.05,1);
  opacity: 1;
  animation: fullRotation 2.5s linear infinite;
}

.custom-cursor--link .custom-cursor-pointer {
  transition: transform 0.3s cubic-bezier(.65,0,0,.99), opacity 0.3s cubic-bezier(.65,0,0,.99);
  transform: translate(100%, 100%) scale(2);
  opacity: 0;
}
