*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 100%;
    scroll-behavior: smooth;
}

body {
    box-sizing: border-box;
}

::-moz-selection {
    color: #EFF0EA;
    background: #393B5D;
}

::selection {
    color: #EFF0EA;
    background: #393B5D;
}

.dark-bg ::selection {
    background: #272847;
}

.dark-bg ::-moz-selection {
    background: #272847;
}

footer a {
    display: inline-block;
    padding-top: 0.25rem;
}


nav {
    a:not(.button) {
        background-position: 0 100%;
        background-repeat: no-repeat;
        padding-bottom: 7px;
        background-size: 0 1px;
        background-image: linear-gradient(#50539C,#50539C);
        transition: background-size .25s ease-in-out, color 0.25s ease-in-out;
    
        &:hover {
            background-size: 100% 1px;
            color: #50539C;
            transition: background-size .15s ease-in-out, color 0.15s ease-in-out;
        }
    }
}

.nav-light nav {
    a:not(.button) {
        color: #EFF0EA;
        background-image: linear-gradient(#EFF0EA,#EFF0EA);
    
        &:hover {
            color: #EFF0EA;
        }
    }
}

.cases a figcaption span {
    background-position: 0 100%;
    background-repeat: no-repeat;
    padding-bottom: 6px;
    background-size: 0 1px;
    background-image: linear-gradient(#50539C,#50539C);
    transition: background-size .25s ease-in-out, color 0.25s ease-in-out;
}

.cases a:hover figcaption span {
    background-size: 100% 1px;
    color: #50539C;
    transition: background-size .15s ease-in-out, color 0.15s ease-in-out;
}


footer, nav .contact {
    a:not(.button) {
        background-position: 0 100%;
        background-repeat: no-repeat;
        padding-bottom: 2px;
        background-size: 100% 1px;
        background-image: linear-gradient(#393B5D,#393B5D);
        transition: background-size .25s ease-in-out, color 0.25s ease-in-out;
    
        &:hover {
            background-size: 0 1px;
            color: #50539C;
            transition: background-size .15s ease-in-out, color 0.15s ease-in-out;
        }
    }
}


img.lazy {
    transition: all 0.3s ease;
}

img.lazyload, img.lazyloading {
    filter: blur(64px);
	opacity: 0.25;
    transform: scale(1.25) translate3d(0, 0, 0);
}

img.lazyloaded {
    filter: blur(0px);
	opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
}

.cases-list-item img.lazyload,
.cases-list-item img.lazyloading {
    opacity: 0.5;
    filter: blur(24px);
    transform: scale(1) translate3d(0, -2rem, 0);
}

.intro-headline {
    text-shadow: 0 0 2rem rgba(57, 59, 93, 1);
}

.scroll-chevron {
    animation: scroll-chevron-animation 2s ease infinite alternate;
}

@keyframes scroll-chevron-animation {
    from { transform: translateY(-0.5rem); }
    to { transform: translateY(0rem); }
}


.fade {
    transition: 0.4s;
    opacity: 1;
}

html.is-animating .fade {
    opacity: 0;
}


.logo-circle-wrapper {
    animation: fullRotation 15s linear infinite;
    // will-change: transform;
}

@keyframes fullRotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}